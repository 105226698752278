.placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    gap: var(--spacing-core-6);
    margin: var(--spacing-core-20) auto 0;
    max-width: 325px;
    
    p {
        font-size: 14px;
    }

    button {
        width: 100%;
    }
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f3f3;
    border-radius: 50%;
    height: var(--sizing-core-12);
    width: var(--sizing-core-12);
}
