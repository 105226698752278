@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

dialog[open].modal {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    border-radius: 0;

    @include mq.mq($until: small) {
        height: 100%;
    }
    
    @include mq.mq($from: medium) {
        max-width: 496px;
        border-radius: var(--border-radius-core-l);
    }
}

.modalHeader {
    padding: var(--spacing-core-6);
}

.modalContent  {
    flex-grow: 1;
    padding: 0 var(--spacing-core-6);
}

.modalFooter {
    padding: var(--spacing-core-6);
    display: flex;
    flex-direction: column;
}

.modalDescription {
    margin-bottom: var(--spacing-core-6);
}

.root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.inputError {
    margin-top: var(--spacing-core-2);
}

.errorWrapper,
.loadingWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-core-4) var(--spacing-core-6);
    margin: 0 calc(var(--spacing-core-6) * -1);
    gap: var(--spacing-core-4);
    background: #f3f6f8;
}

.errorIcon {
    fill: var(--color-action-destructive-default);
}

.recipeImageWrapper {
    width: 100%;
    height: 160px;
    border-radius: var(--border-radius-core-m);
    overflow: hidden;
    margin: 10px 0;
    background: #f3f6f8;
}

.recipeImage {
    margin: 0;
    height: 160px;
    overflow: hidden;
    padding-top: 0;

    img {
        top: 50%;
        transform: translateY(-50%);
    }
}

.imageSkeleton {
    width: 100%;
    height: 160px;
    border-radius: var(--border-radius-core-m);
}

.retryButton {
    background: #f3f6f8;
    border-color: #f3f6f8;
}

.retryButtonIcon {
    color: var(--color-foreground-primary-default);
}

.retryButtonText {
    color: #000;

    span {
        color: var(--color-foreground-primary-default);
    }
}

.retryButtonWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    place-content: center center;
    flex-direction: column;
}

.inputNameLabel {
    margin-bottom: var(--spacing-core-2);
}

.input {
    width: 100%;
    overflow: hidden;
    padding: var(--spacing-core-3) var(--spacing-core-10) var(--spacing-core-3) var(--spacing-core-4);
    border-radius: var(--border-radius-core-m);
}

.hostname {
    color: #8499ab;
}

.inputHeader {
    display: flex;
    justify-content: space-between;
}
